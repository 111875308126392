import { apiClient } from './axiosConfig';
import chunk from './chunk';
import { logOut } from "../components/LoginComponent/Login.helpers";
import { removeLocalStorage } from './storageHelpers';
import * as Sentry from "@sentry/react";
import {DateTime} from "luxon";

const admin_url = process.env.REACT_APP_ADMIN_URL;
const user_url = process.env.REACT_APP_USER_URL;
const export_url = process.env.REACT_APP_EXPORT_URL;
const data_url = process.env.REACT_APP_DATA_URL;

export default class ApiData {
    /****** admin api ********/
    static getUsers(pageIndex, pageLimit = 20, sort, sortType, searchKey){
        const page = pageIndex?pageIndex: 1;
        return ApiData.getJson(`/list`, {page: page, num: pageLimit, sort, asc: sortType,searchKey:searchKey}, true);
    }
    static getGroups(pageIndex, pageLimit, sort, sortType, searchKey){
        const page = pageIndex?pageIndex: 1;
        return ApiData.getJson(admin_url+`/group/list`, {page: page, num: pageLimit, sort, asc: sortType,searchKey:searchKey}, true);
    }
    static getGroup(groupId){
        return ApiData.getJson(`/group/${groupId}`);
    }
    static getGroupDetailed(groupId){
        return ApiData.getJson(admin_url+`/group/${groupId}/detailed`);
    }

    static searchGroupByName(name){
        return ApiData.getJson(admin_url+`/group/search`, {name: name});
    }

    static searchUserByname(name){
        return ApiData.getJson(`/search`, {name: name});
    }

    static createGroup(data){
        return ApiData.postJson(admin_url+'/group', data);
    }

    static createGroupPartial(data){
        return ApiData.postJson(admin_url+'/group/create', data);
    }

    static removeGroup(groupId) {
        return ApiData.delete(admin_url+`/group/delete/${groupId}`);
    }

    static updateGroup(groupId, groupData) {
        return ApiData.putJson(admin_url+`/group/update/${groupId}`, groupData);
    }

    static updateGroupPartial(groupId, groupData) {
        return ApiData.putJson(admin_url+`/group/update/${groupId}`, groupData);
    }

    static getContract(contractId){
        return ApiData.getJson(admin_url+`/contract/${contractId}`);
    }
    static getQuotas(contractId){
        return ApiData.getJson(admin_url+`/contract/${contractId}/quotas`);
    }
    static getContractsForGeo(contractId){
        return ApiData.getJson(`/contract/geo/${contractId}/list`);
    }
    static suspendContract(contractId, status = true){
        return ApiData.putJson(`/contract/${contractId}/suspend?suspend=${status}`);
    }

    static updateContract(contractId, contractData) {
        return ApiData.putJson(admin_url+`/contract/${contractId}/update`, contractData);
    }

    static updateContractPartial(contractId, contractData) {
        return ApiData.putJson(admin_url+`/contract/update/${contractId}`, contractData);
    }

    static createContract(data,userId){
        return ApiData.postJson(admin_url+`/contract/create`, data);
    }

    static getUserById(userId) {
        return ApiData.getJson(`/${userId}`);
    }

    static getUserDetailed(userId) {
        return ApiData.getJson(`/${userId}/detailed`);
    }

    static updateUser(userId, userData){
        return ApiData.putJson(`/${userId}`, userData);
    }

    static updateUserRole(userId, userRole){
        return ApiData.putJson(`/${userId}/role?userRole=${userRole}`);
    }

    static removeUser(userId) {
        return ApiData.delete(`/${userId}`);
    }

    static resetPassword(userId){
        return ApiData.postJson(`/${userId}/reset-password`);
    }

    static enableUser(userId, isActive){
        return ApiData.putJson(`/${userId}/isActive?isActive=${isActive}`);
    }

    static addUsertoGroup(groupId, userId) {
        return ApiData.putJson(`/${userId}/group/${groupId}`);
    }

    static addBrandtoContract(contractId, brandIdList){
        return ApiData.putJson(`/contract/${contractId}/update`, {brandIds: brandIdList} );
    }
    static deleteBrandFromContract(contractId, brandIdList){
        return ApiData.putJson(`/contract/${contractId}/removeBrands`, brandIdList );
    }
    static removeUserfromGroup(groupId, userId) {
        return ApiData.delete(`/${userId}/group/${groupId}`);
    }
    static getFiltersCounts(groupId) {
        return ApiData.getJson(admin_url+`/filter/getFiltersCount/${groupId}`);
    }
    static getAllMarketList(groupId) {
        return ApiData.getJson(admin_url+`/filter/${groupId}/getAllMarketListandCount`);
    }
    static getAllSearchFiltersTypes(groupId) {
        return ApiData.getJson(admin_url+`/filter/all/${groupId}`);
    }
    static getAllFiltersList(groupId, params='') {
        return ApiData.getJson(admin_url+`/filter/getFiltersByCategory/${groupId}`, params);
    }
    static getGroupFiltersList(groupId, params) {
        return ApiData.getJson(admin_url+`/filter/${groupId}/list`, params, true);
    }
    static createFilters(data, userID) {
        return ApiData.postJson(admin_url+`/filter/${userID}/create`, data);
    }
    static validateBrandById(payload){
        return ApiData.postJson(admin_url+`/brand/validate`,payload);
    }
    static editFilters(groupId, payload) {
        return ApiData.putJson(admin_url+`/filter/${groupId}`, payload);
    }
    static removeFilters(groupId, payload) {
        return ApiData.delete(admin_url+`/filter/${groupId}`, payload);
    }
    static searchCompany(company){
        return ApiData.postJson(admin_url+`/group/company/search`, {company: company});
    }
    
    static getinternalOwners(searchKey){
        return ApiData.getJson(admin_url+`/group/internalSearch`, { searchKey:searchKey});
    }

    static getIndustryList(){
        return ApiData.getJson(admin_url+'/brand/industries')
    }

    /****** user api ********/
    static createUser(userData) {
        return ApiData.putJson(`/create`, userData);
    }
    static getUserInfo(){
        return ApiData.getJson(`/details`);
    }
    static getUserContracts(){
        return ApiData.getJson(`/contracts`);
    }
    static getUserBrands(){
        return ApiData.getJson(`/brands`);
    }

    static createUserBrands(data){
        return ApiData.postJson(`/contract`, data);
    }

    static getUserSegments(geoIds){
        let marketsPayload = {geoIds: [geoIds]}
        return ApiData.postJson(`/segment/list`,marketsPayload);
    }

    static validateSegments(payload){
        return ApiData.postJson('/sharedsegment/validate', payload);
    }

    static addSharedSegments(payload){
        return ApiData.postJson('/sharedsegment', payload);
    }

    static createSegment(data){
        return ApiData.postJson(`/segment/create`, data);
    }

    static updateUserSegment(data){
        let {segmentId, payload} = data;
        return ApiData.putJson(`/segment/${segmentId}/update`, payload);
    }

    static deleteUserSegment(segmentId){
        return ApiData.delete(`/segment/${segmentId}/delete`);
    }

    static getUserCategories(){
        return ApiData.getJson(`/segment/categories`);
    }

    static updateSegmentColor(data){
        let {segmentId, payload} = data;
        return ApiData.putJson(`/segment/${segmentId}/update/color`,payload);
    }

    static updateCategory(categoryId, data){
        return ApiData.putJson(`/segment/category/${categoryId}/update`,data);
    }

    static deleteCategory(data){
        let {categoryId, payload} = data;
        return ApiData.delete(`/segment/category/${categoryId}/delete`,payload);
    }
    
    static updateSegmentsOrder(data){
        return ApiData.putJson(`/segment/update/order`,data);
    }

    static updateCategoryOrder(data){
        return ApiData.putJson(`/segment/category/update/order`,data);
    }

    static enableDisableSegment(data){
        return ApiData.putJson(`segment/update/status`,data);
    }

    static updateSegmentsOrderOrColour(userId,data){
        return ApiData.postJson(`/${userId}/segments/updateSettings`,data);
    }

    static updateBrandsOrderOrColour(userId,marketId,data){
        return ApiData.postJson(`/${userId}/${marketId}/brands/updateSettings`,data);
    }

    static updateGeoOrderOrColour(userId,data){
        return ApiData.postJson(`/${userId}/geo/updateSettings`,data);
    }

    static getCategories(){
        return ApiData.getJson(`/category/list`);
    }

    static getSubCategories(categoryId){
        return ApiData.getJson(`/category/${categoryId}/listSubCats`);
    }

    static getCategory(categoryId){
        return ApiData.getJson(`/category/${categoryId}`);
    }

    static getBrand(brandId){
        return ApiData.getJson(`/brand/${brandId}`);
    }

    static getBrandDetailed(brandId, sort, sortType){ 
        return ApiData.getJson(admin_url+`/brand/${brandId}/detailed`,{ sort, asc: sortType });
    }

    static getBrandList(status, pageIndex, pageLimit, sort, sortType, searchKey){
        const page = pageIndex?pageIndex: 1;
        return ApiData.getJson(admin_url+`/brand/list/get`, {status: status, page: page, num: pageLimit, sort, asc: sortType, searchKey:searchKey}, true);
    }

    static approveBrand(brandId){
        return ApiData.putJson(admin_url+`/brand/${brandId}/verify`);
    }

    static updateBrand(brandId, payload){
        return ApiData.putJson(admin_url+`/brand/update/${brandId}`, payload);
    }

    static deleteBrand(brandId){
        return ApiData.delete(admin_url+`/brand/delete/${brandId}`);
    }

    static searchBrandByname(name){
        return ApiData.getJson(admin_url+`/brand/search`, {name: name});
    }

    static searchCampaignByname(name){
        return ApiData.getJson(admin_url+`/market/search`, {name: name});
    }

    static createBrand(data){
        return ApiData.postJson(admin_url+`/brand/create`, data);
    }

    static getDecipherSurveys() {
        return ApiData.getJson(admin_url+`/market/market-surveys`);
    }

    static getCampaignList(status, pageIndex, pageLimit, sort, sortType, searchKey){
        const page = pageIndex?pageIndex: 1;
        return ApiData.getJson(admin_url+`/market/list`, {status: status, page: page, num: pageLimit, sort, asc: sortType, searchKey:searchKey}, true);
    }

    static getCampaignDetailed(campaignId,sort, sortType,){
        return ApiData.getJson(admin_url+`/market/${campaignId}/detailed`, {sortContract:sort, asc: sortType});
    }

    static flipCampaignStatus(campaignId){
        return ApiData.putJson(admin_url+`/market/${campaignId}/flipStatus`);
    }

    static updateCampaign(campaignId, campaignData){
        return ApiData.putJson(admin_url+`/market/update/${campaignId}`, campaignData);
    }

    static createCampaign(campaignData) {
        return ApiData.postJson(admin_url+`/market/create`, campaignData);
    }

    static createDecipherSurvey(decipherSurveyData) {
        return ApiData.postJson(admin_url+`/market/create/market-survey`, decipherSurveyData);
    }

    static getGeoBrands(campaignId){
        return ApiData.getJson(admin_url+`/market/${campaignId}/brands`);
    }

    static getUserLogout(){
        return ApiData.getJson(`/auth/logout`);
    }

    static resendVerificationEmail(){
        return ApiData.postJson(`/auth/email`);
    }

    static setPrimaryBrand(groupId, brandId) {
        return ApiData.putJson(`/group/${groupId}/main-brand/${brandId}`);
    }

    static removePrimaryBrand(groupId) {
        return ApiData.delete(`/group/${groupId}/main-brand`);
    }

    static codeVerification(code) {
        return ApiData.jsonRequest(`/register/verify`, 'getnoauth', {code: code}, null, null,true);
    }

    static searchCampaignByNameOrRegion(searchValue) {
        return ApiData.getJson(admin_url+`/market/search`, {value: searchValue});
    }

    static getCampaigns() {
        return ApiData.getJson(admin_url+`/market`);
    }

    static getAllCampaigns() {
        return ApiData.getJson(admin_url+`/market/all`);
    }

    static getUserBrandsByCampaign(userID,marketID) {
        return ApiData.getJson(`brand/list/${marketID}/${userID}`);
    }
    
    /******* data api *********/
    static getUpdated(payload){
        return ApiData.postJson(data_url+`/updated-time`,payload);
    }

    static getFilters(payload){
        return ApiData.postJson(user_url+`/filters`,payload);
    };

    static getFilterNamesMapping() {
        return ApiData.getJson(admin_url + `/search/filters/names-map`);
    }

    static getBrandConversionData(brandId,payload,byDate){
        let bd_url=byDate?'/by-date':'';
        return ApiData.postJson(admin_url+`/brand/${brandId}/conversion${bd_url}`,payload);
    }
   
    static getCampaignListForBrand(brandId){
        return ApiData.getJson(user_url+`/brand/${brandId}/markets`);
    }

    static getGeoEquityData(payload,byDate){
        let bd_url=byDate?`/trend`:'';
        return ApiData.postJson(data_url+`${bd_url}/equity`,payload);
    }

    static getGeoConversionData(payload,byDate){
        let bd_url=byDate?`/trend`:'';
        return ApiData.postJson(data_url+`${bd_url}/conversion`,payload);
    }

    static getGeoImageryData(payload,byDate){
        let bd_url=byDate?`/trend`:'';
        return ApiData.postJson(data_url+`${bd_url}/emotional`,payload);
    }

    static getGeoAdvertisingData( payload, byDate){
        let bd_url=byDate?`/trend`:'';
        return ApiData.postJson(data_url+`${bd_url}/advertising`,payload);
    }

    static getGeoIrreplaceabilityData(payload,byDate){
        let bd_url=byDate?`/trend`:'';
        return ApiData.postJson(data_url+`${bd_url}/irreplaceability`,payload);
    }

    static getBrandEquityData(brandId,payload,byDate){
        let bd_url=byDate?'/by-date':'';
        return ApiData.postJson(admin_url+`/brand/${brandId}/equity${bd_url}`,payload);
    }

    static getBrandImageryData(brandId,payload,byDate){
        let bd_url=byDate?'/by-date':'';
        return ApiData.postJson(admin_url+`/brand/${brandId}/imagery${bd_url}`,payload);
    }
    
    static getGeoAsyncImageryData(brandId,payload,byDate,campaignId){
        let bd_url=byDate?`/${campaignId}/by-date`:'';
        return ApiData.postJson(admin_url+`/brand/${brandId}/asyncImageryPerCampaign${bd_url}`,payload);
    }
    static getBrandMiscData(brandId,payload,byDate){
        let bd_url=byDate?'/by-date':'';
        return ApiData.postJson(admin_url+`/brand/${brandId}/misc${bd_url}`,payload);
    }
    static getBrandCampaigns(brandId, sort, sortType) {
        return ApiData.getJson(admin_url+`/brand/${brandId}/markets`, { sort, asc: sortType });
    }

    static getBrandTouchpointData(brandId,payload,byDate){
        let bd_url=byDate?'/by-date':'';
        return ApiData.postJson(admin_url+`/brand/${brandId}/touchpoint${bd_url}`,payload);
    }
    static getGeoTouchpointData(brandId,payload,byDate, campaignId){
        let bd_url=byDate?`/${campaignId}/by-date`:'';
        return ApiData.postJson(admin_url+`/brand/${brandId}/touchpointPerCampaign${bd_url}`,payload);
    }
   
    static getSummaryData(payload){
        return ApiData.postJson(data_url+`/dashboard/summary/all`, payload);
    }
    static getSummaryTrendsData(payload){
        return ApiData.postJson(data_url+`/dashboard/summary/trend/equity`, payload);
    }
    static getBrandDifferentiationData(brands,filters){
        const brandId=brands[0];
        let competitors_param = '?competitors%5B%5D=' + brands[1];
        brands.forEach((b, index)=>{
            if (index > 1 ) {
                competitors_param += '&competitors%5B%5D=' + b;
            }
        })
        return ApiData.postJson(admin_url+`/brand/${brandId}/da${competitors_param}`, {filters:filters});
    }

    // static getGeoBrandDifferentiationData(brands,filters,campaignId){
    //     const brandId=brands[0];
    //     let competitors_param = '?competitors%5B%5D=' + brands[1];
    //     brands.forEach((b, index)=>{
    //         if (index > 1 ) {
    //             competitors_param += '&competitors%5B%5D=' + b;
    //         }
    //     })
    //     return ApiData.postJson(admin_url+`/brand/${brandId}/daPerCampaign/${campaignId}${competitors_param}`, {filters:filters});
    // }
    static getGeoBrandDifferentiationData(payload){
        // const brandId=brands[0];
        // let competitors_param = '?competitors%5B%5D=' + brands[1];
        // brands.forEach((b, index)=>{
        //     if (index > 1 ) {
        //         competitors_param += '&competitors%5B%5D=' + b;
        //     }
        // })
        return ApiData.postJson(data_url+`/da`, payload);
    }

    static getBrandPerceptualData(brands,filters){
        const brandId=brands[0];
        let competitors_param = '?competitors%5B%5D=' + brands[1];
        brands.forEach((b, index)=>{
            if (index > 1 ) {
                competitors_param += '&competitors%5B%5D=' + b;
            }
        })
        return ApiData.postJson(admin_url+`/brand/${brandId}/ca${competitors_param}`, {filters:filters});
    }

    static getGeoBrandPerceptualData(payload){
        // const brandId=brands[0];
        // let competitors_param = '?competitors%5B%5D=' + brands[1];
        // brands.forEach((b, index)=>{
        //     if (index > 1 ) {
        //         competitors_param += '&competitors%5B%5D=' + b;
        //     }
        // })
        return ApiData.postJson(data_url+`/ca`,payload);
    }

    static getBrandButterflyData(brands,filters, globalBrandEngagement){
        const brandId=brands[0];
        let competitors_param = '?competitor=' + brands[1];
        const body = {filters:filters}
        if (globalBrandEngagement) {
            body.globalBrandEngagement = globalBrandEngagement
        }
        return ApiData.postJson(admin_url+`/brand/${brandId}/butterfly${competitors_param}`, body);
    }

    static getGeoBrandButterflyData(payload){
        // const brandId=brands[0];
        // let competitors_param = '?competitor=' + brands[1];
        // const body = {filters:filters}
        // if (globalBrandEngagement) {
        //     body.globalBrandEngagement = globalBrandEngagement
        // }
        return ApiData.postJson(data_url+`/butterfly`, payload);
    }

    static getBrandEquityPrePostData(brandId,payload){
        return ApiData.postJson(admin_url+`/brand/${brandId}/equity/prepost`, payload);
    }

    static getGeoBrandPrePostData(payload){
        return ApiData.postJson(data_url+`/prepost`, payload);
    }

    static getBrandConversionPrePostData(payload){
        return ApiData.postJson(data_url+`/prepost/conversion `, payload);
    }

    static getGeoBrandConversionPrePostData(payload){
        return ApiData.postJson(data_url+`/prepost/conversion `, payload);
    }

    static getBrandEmotionalPrePostData(brandId,payload){
        return ApiData.postJson(admin_url+`/brand/${brandId}/imagery/prepost`, payload);
    }

    static getGeoBrandEmotionalPrePostData(payload){
        return ApiData.postJson(data_url+`/prepost/emotional`, payload);
    }

    static getBrandTouchpointsPrePostData(brandId,payload){
        return ApiData.postJson(admin_url+`/brand/${brandId}/touchpoint/prepost`, payload);
    }

    static getGeoBrandAdvertisingPrePostData(payload){
        return ApiData.postJson(data_url+`/prepost/advertising`, payload);
    }

    static getCampaignBrands(campaignId, status, pageIndex, pageLimit, sort, sortType){
        const page = pageIndex?pageIndex: 1;
        return ApiData.getJson(admin_url+`/market/${campaignId}/brand/list`, {status: status, page: page, num: pageLimit, sort, asc: sortType}, true);
    }

    static getExportAllJobId(brandId, payload, params) {
        return ApiData.postJson(`${admin_url}/export/${brandId}/all`, payload, params, true, { responseType: 'text' });
    }

    static getExportSummaryJobId(payload, params, brandId, campaignId) {
        return ApiData.postJson(`${admin_url}/export/${brandId}/summary/campaign/${campaignId}`, payload, params, true, { responseType: 'text' });
    }

    static getExportJobId(){
        return ApiData.getJson(`${export_url}/ws`, '', true );
    }

    static getExports(payload, params) {
        return ApiData.postJson(`${export_url}/export`, payload, params, true, { responseType: 'text' });
    }

    static getSegmentsExport(payload, params, subRoute ) {
        return ApiData.postJson(`${export_url}/segment/${subRoute}`, payload, params, true, { responseType: 'text' });
    }
    static getCompetitiveExports(payload, params, subtitle ) {
        
        return ApiData.postJson(`${export_url}/competitive/${subtitle}`, payload, params, true, { responseType: 'text' });
    }
    static getGlobalExports(payload, params, subtitle) {
        
        return ApiData.postJson(`${export_url}/global/${subtitle}`, payload, params, true, { responseType: 'text' });
    }

    static getExportEquityGlobalJobId(payload, params) {
        return ApiData.postJson(`${admin_url}/export/equity/global`, payload, params, true,{ responseType: 'text' });
    }

    static getExportConversionGlobalJobId(payload, params) {
        return ApiData.postJson(`${admin_url}/export/conversion/global`, payload, params, true,{ responseType: 'text' });
    }

    static getExportImageryGlobalJobId(payload, params) {
        return ApiData.postJson(`${admin_url}/export/imagery/global`, payload, params, true,{ responseType: 'text' });
    }

    static getExportTouchpointGlobalJobId(payload, params) {
        return ApiData.postJson(`${admin_url}/export/touchpoint/global`, payload, params, true,{ responseType: 'text' });
    }

    static getExportEquityTrendGlobalJobId(payload, params) {
        return ApiData.postJson(`${admin_url}/export/trendEquity/global`, payload, params, true,{ responseType: 'text' });
    }

    static getExportConversionTrendGlobalJobId(payload, params) {
        return ApiData.postJson(`${admin_url}/export/trendConversion/global`, payload, params, true,{ responseType: 'text' });
    }

    static getExportTouchpointTrendGlobalJobId(payload, params) {
        return ApiData.postJson(`${admin_url}/export/trendTouchpoint/global`, payload, params, true,{ responseType: 'text' });
    }

    static getExportImageryTrendGlobalJobId(payload, params) {
        return ApiData.postJson(`${admin_url}/export/trendImagery/global`, payload, params, true,{ responseType: 'text' });
    }

    static getExportTwoByTwoGlobalJobId(brandId, payload, fileType) {
        return ApiData.postJson(admin_url+`/export/twoXTwo/global/brand/${brandId}?fileType=${fileType}`, payload, '', true, { responseType: 'text' });
    }

    static getExportVariableGlobalJobId(brandId, filterId, campaignIds, fileType, payload, withGenpop) {
        let campaignIdsParam = ''

        if (campaignIds && campaignIds.length) {
            campaignIds.forEach((c, index) => {
                if (index > 0) {
                    campaignIdsParam += '&campaignId=' + c;
                } else {
                    campaignIdsParam += '?campaignId=' + c;
                }
            })
        }

        return ApiData.postJson(`${admin_url}/export/variable/global/${filterId}/brand/${brandId}${campaignIdsParam}&fileType=${fileType}&withGenpop=${withGenpop}`, payload, '', true,{ responseType: 'text' });
    }

    static getExportConversionJobId(brandId, payload, params, urlOption, campaignId, brandIds) {
        return ApiData.postJson(`${export_url}/segment/conversion`, payload, params, true, { responseType: 'text' });
    }

    static getExportImageryJobId(brandId, payload, params, urlOption, campaignId, brandIds) {
        // const pl = {
        //     "geoIds":[1],
        //     "marketId":1,
        //     "brandIds": [590],
        //     "fromDate":"2022-01-31",
        //     "toDate":"2022-12-31",
        //     "intervalUnit":"WEEKS",
        //     "segments":[{
        //     "segmentId":1,
        //     "segmentName":"APC",
        //     "filters":[]
        //     },
        //     {
        //     "segmentId":2,
        //     "segmentName":"APC",
        //     "filters":[]
        //     }
        //     ]
        //     };

        return ApiData.postJson(`${export_url}/segment/imagery`, payload, params, true, { responseType: 'text' });
    }

    static getExportTouchpointJobId(brandId, payload, params, urlOption, campaignId, brandIds) {
        return ApiData.postJson(`${export_url}/segment/advertising`, payload, params, true, { responseType: 'text' });
    }

    static getExportDetailedVariableJobId(brandId, campaignId, filterId, fileType, payload) {
        return ApiData.postJson(admin_url+`/export/${brandId}/${filterId}/variable/campaign/${campaignId}?fileType=${fileType}`, payload, '', true, { responseType: 'text' });
    }

    static getExportDetailedTwoByTwoJobId(brandId, campaignId, payload, fileType) {
        return ApiData.postJson(admin_url+`/export/${brandId}/campaign/${campaignId}/twoXTwo/detailed?fileType=${fileType}`, payload, '', true, { responseType: 'text' });
    }

    static getExportCompetitiveTwoByTwoJobId(brandId, campaignId, payload, fileType) {
        return ApiData.postJson(admin_url+`/export/${brandId}/twoXTwo/campaign/${campaignId}/competitive?fileType=${fileType}`, payload, '', true, { responseType: 'text' });
    }

    static getExportCompetitiveVariableJobId(brands, filterId, fileType, payload, campaignId) {
        const brandId=brands[0].id;
        let competitors_param = '?competitors=' + brands[1].id;
        brands.forEach((b, index)=>{
            if (index > 1 ) {
                competitors_param += '&competitors=' + b.id;
            }
        })
        return ApiData.postJson(admin_url+`/export/${brandId}/${filterId}/variable/campaign/${campaignId}/competitive${competitors_param}&fileType=${fileType}`, payload, '', true, { responseType: 'text' });
    }

    static getExportCompetitiveEquityTrendsJobId(brandId, payload, params, campaignId, brandIds) {
        let brandIdsParam = '';
        if(brandIds && brandIds.length) {
            brandIdsParam = `?brandIds%5B%5D=${brandIds[0]}`;
            brandIds.forEach((b, index)=>{
                if (index > 0 ) {
                    brandIdsParam += '&brandIds%5B%5D=' + b;
                }
            });
        }
        return ApiData.postJson(`${admin_url}/export/${brandId}/trendEquity/campaign/${campaignId}/competitive${brandIdsParam}`, payload, params, true, { responseType: 'text' });
    }

    static getExportCompetitiveConversionTrendsJobId(brandId, payload, params, campaignId, brandIds) {
        let brandIdsParam = '';
        if(brandIds && brandIds.length) {
            brandIdsParam = `?brandIds%5B%5D=${brandIds[0]}`;
            brandIds.forEach((b, index)=>{
                if (index > 0 ) {
                    brandIdsParam += '&brandIds%5B%5D=' + b;
                }
            });
        }
        return ApiData.postJson(`${admin_url}/export/${brandId}/trendConversion/campaign/${campaignId}/competitive${brandIdsParam}`, payload, params, true, { responseType: 'text' });
    }

    static getExportCompetitiveImageryTrendsJobId(brandId, payload, params, campaignId, brandIds) {
        let brandIdsParam = '';
        if(brandIds && brandIds.length) {
            brandIdsParam = `?brandIds%5B%5D=${brandIds[0]}`;
            brandIds.forEach((b, index)=>{
                if (index > 0 ) {
                    brandIdsParam += '&brandIds%5B%5D=' + b;
                }
            });
        }
        return ApiData.postJson(`${admin_url}/export/${brandId}/trendImagery/campaign/${campaignId}/competitive${brandIdsParam}`, payload, params, true, { responseType: 'text' });
    }

    static getExportCompetitiveTouchpointTrendsJobId(brandId, payload, params, campaignId, brandIds) {
        let brandIdsParam = '';
        if(brandIds && brandIds.length) {
            brandIdsParam = `?brandIds%5B%5D=${brandIds[0]}`;
            brandIds.forEach((b, index)=>{
                if (index > 0 ) {
                    brandIdsParam += '&brandIds%5B%5D=' + b;
                }
            });
        }
        return ApiData.postJson(`${admin_url}/export/${brandId}/trendTouchpoint/campaign/${campaignId}/competitive${brandIdsParam}`, payload, params, true, { responseType: 'text' });
    }

    static getExportPrePostJobId(brandId, payload, params, bool, campaignId) {
        return ApiData.postJson(`${admin_url}/export/${brandId}/prepost/all/campaign/${campaignId}`, payload, params, true, { responseType: 'text' });
    }

    static getExportButterflyJobId(brands, payload, params, bool, campaignId){
        const brandId=brands[0];
        const addParams = {...params, competitor: brands[1]}
        return ApiData.postJson(admin_url+`/export/${brandId}/butterfly/campaign/${campaignId}`, payload, addParams, true, { responseType: 'text' });
    }


    static getAdvancedExportJobId(payload, params, selectedTab){
        return ApiData.postJson(export_url+`/advanced/${selectedTab}`, payload, params, true, { responseType: 'text' });
    }

    static getExportAllDetailedJobId(brandId, payload, params, byDate, campaignId) {
        const bd_url = byDate ? '/by-date' : '';
        return ApiData.postJson(`${export_url}/export/${brandId}/detailed/campaign/${campaignId}/all${bd_url}`, payload, params, true, { responseType: 'text' });
    }

    static getExportAllGlobalJobId(brandId, campaignIds, payload, params) {
        let campaignIdsParam = ''

        if (campaignIds && campaignIds.length) {
            campaignIds.forEach((c, index) => {
                if (index > 0) {
                    campaignIdsParam += '&campaignId=' + c;
                } else {
                    campaignIdsParam += '?campaignId=' + c;
                }
            })
        }

        return ApiData.postJson(`${admin_url}/export/all/global/${brandId}${campaignIdsParam}`, payload, params, true, { responseType: 'text' });
    }

    static getExportAllCompetitiveJobId(brandId, payload, params, campaignId, brandIds) {
        let brandIdsParam = '';
        if(brandIds && brandIds.length) {
            brandIdsParam = `?brandIds%5B%5D=${brandIds[0]}`;
            brandIds.forEach((b, index)=>{
                if (index > 0 ) {
                    brandIdsParam += '&brandIds%5B%5D=' + b;
                }
            });
        }
        return ApiData.postJson(`${admin_url}/export/${brandId}/campaign/${campaignId}/competitive/all${brandIdsParam}`, payload, params, true, { responseType: 'text' });
    }

    static getExportAllAdvancedJobId(brandId, payload, params, bool, campaignId){
        return ApiData.postJson(admin_url+`/export/${brandId}/advanced/campaign/${campaignId}/all`, payload, params, true, { responseType: 'text' });
    }

    static getExportAllDashboardsJobId(brandId, campaignId, brandIds, data, params){
        let brandIdsParam = '';
        if(brandIds && brandIds.length) {
            brandIdsParam = `?brandIds%5B%5D=${brandIds[0]}`;
            brandIds.forEach((b, index)=>{
                if (index > 0 ) {
                    brandIdsParam += '&brandIds%5B%5D=' + b;
                }
            });
        }
        return ApiData.postJson(
            admin_url+`/export/dashboard/${brandId}/campaign/${campaignId}/all${brandIdsParam}`,
            data,
            params,
            true,
            { responseType: 'text' },
        );
    }
    
    static checkQueryById(queryId) {
        return ApiData.getJson(`${admin_url}/brand/getQueryData/${queryId}`, '', true);
    }

    static getExportDataDocument(jobId,exportDashboardTab,fileType, onProgressCb){

        return new Promise(async (resolve, reject) => {
            const config = {
                method: 'get',
                url: `${export_url}/export/file/${jobId}${exportDashboardTab}/${fileType}`,
                onDownloadProgress: (progressEvent) => {
                    onProgressCb && onProgressCb(progressEvent.total, progressEvent.loaded);
                },
                responseType: 'blob',
            };

            apiClient.request(config)
                .then((response) => {
                    if (response.statusCode === 200 || response.status === 200) {
                        resolve(response)
                    } else {
                       ApiData.handleError(response, null, reject);
                    }
                })
                .catch((err) => {
                    console.log('error', err)
                    ApiData.handleError(null, err, reject);
                })
        });
    }

    static getExportImage(url){
        // return new Promise((resolve, reject) => {
        //     const config = {
        //         method: 'getimg',
        //         url: url,
        //         responseType: 'blob',
        //     };
        //
        //     apiClient.request(config)
        //         .then((response) => {
        //             if (response.status === 200) {
        //                 resolve(response)
        //             } else {
        //                ApiData.handleError(response, null, reject);
        //             }
        //         })
        //         .catch((err) => {
        //             console.log('error', err)
        //             ApiData.handleError(null, err, reject);
        //         })
        // });

        /*
            For some reason sometimes axios behaves itself in an unexpected way.
            For example, we did update the logo for one of the brands - request for the new image will fail due to the CORS issues.
            Request headers for that image will be significantly different compared to other (non-updated) images.
         */
        return new Promise((resolve, reject) => {
            const config = {
                method: 'GET',
                mode: 'no-cors',
                cache: 'no-cache',
                responseType: 'blob',
            };
            fetch(url, config)
                .then((response) => {
                    if (response.status !== 200) throw new Error('Error while trying to download the image');

                    return response.blob();
                })
                .then((blob) => resolve({ data: blob }))
                .catch((err) => {
                    ApiData.handleError((null, err, reject));
                })
        });
    }

    static getUserExportDocument() {

        return new Promise(async (resolve, reject) => {
            const config = {
                method: 'GET',
                
                url: `${export_url}/user/export/all/`,
                responseType: 'blob',
            };

            apiClient.request(config)
                .then((response) => {
                    if (response.statusCode === 200 || response.status === 200) {
                        resolve(response)
                    } else {
                       ApiData.handleError(response, null, reject);
                    }
                })
                .catch((err) => {
                    console.log('error', err)
                    ApiData.handleError(null, err, reject);
                })
        });
    }

    static getGroupExportDocument() {

        return new Promise(async (resolve, reject) => {
            const config = {
                method: 'GET',
                url: `${export_url}/admin/group/export/all/`,
                responseType: 'blob',
            };

            apiClient.request(config)
                .then((response) => {
                    if (response.statusCode === 200 || response.status === 200) {
                        resolve(response)
                    } else {
                       ApiData.handleError(response, null, reject);
                    }
                })
                .catch((err) => {
                    console.log('error', err)
                    ApiData.handleError(null, err, reject);
                })
        });
    }

    static getBrandExportDocument() {

        return new Promise(async (resolve, reject) => {
            const config = {
                method: 'GET',
                url: `${export_url}/admin/brand/export/all/`,
                responseType: 'blob',
            };

            apiClient.request(config)
                .then((response) => {
                    if (response.statusCode === 200 || response.status === 200) {
                        resolve(response)
                    } else {
                       ApiData.handleError(response, null, reject);
                    }
                })
                .catch((err) => {
                    console.log('error', err)
                    ApiData.handleError(null, err, reject);
                })
        });
    }

    static getContractExportDocument() {

        return new Promise(async (resolve, reject) => {
            const config = {
                method: 'GET',
                url:`${export_url}/admin/contract/export/all/`,
                responseType: 'blob',
            };

            apiClient.request(config)
                .then((response) => {
                    if (response.statusCode === 200 || response.status === 200) {
                        resolve(response)
                    } else {
                        ApiData.handleError(response, null, reject);
                    }
                })
                .catch((err) => {
                    console.log('error', err)
                    ApiData.handleError(null, err, reject);
                })
        });

    }

    static getMarketExportDocument(userTimezone) {
        userTimezone = userTimezone ?? DateTime.local().zoneName;

        return new Promise(async (resolve, reject) => {
            const config = {
                method: 'GET',
                url: `${export_url}/admin/market/export/all/`,
                responseType: 'blob',
                params: {
                    userTimezone: userTimezone
                }
            };

            apiClient.request(config)
                .then((response) => {
                    if (response.statusCode === 200 || response.status === 200) {
                        resolve(response)
                    } else {
                       ApiData.handleError(response, null, reject);
                    }
                })
                .catch((err) => {
                    console.log('error', err)
                    ApiData.handleError(null, err, reject);
                })
        });
    }


    static getDetailedVariableData(brandId, filterId, payload){
        return ApiData.postJson(admin_url+`/brand/${brandId}/${filterId}/variable`, payload);
    }

    static getGeoDetailedVariableData(payload){
        return ApiData.postJson(data_url+`/profile`, payload);
    }

    static getCompetitiveVariableData(brands, filterId, withGenpop, payload){
        const brandId=brands[0].id;
        let competitors_param = '?competitors=' + brands[1].id;
        brands.forEach((b, index)=>{
            if (index > 1 ) {
                competitors_param += '&competitors=' + b.id;
            }
        });
        const withGenpopParam = `&withGenpop=${withGenpop}`;
        return ApiData.postJson(admin_url+`/brand/${brandId}/${filterId}/compVariable${competitors_param}${withGenpopParam}`, payload);
    }

    static getGeoCompetitiveVariableData(payload){
        // const brandId=brands[0].id;
        // let competitors_param = '?competitors=' + brands[0].id;
        // if(brands.length > 1) {
        //     competitors_param = '?competitors=' + brands[1].id;
        //     brands.forEach((b, index) => {
        //         if (index > 1) {
        //             competitors_param += '&competitors=' + b.id;
        //         }
        //     });
        // }
        // const withGenpopParam = `&withGenpop=${withGenpop}`;
        return ApiData.postJson(data_url+`/profile`, payload);
    }
    
    static getGeoVariableData(payload){
        // let campaign_param = '?campaigns=' + campaigns[0];
        // campaigns.forEach((b, index)=>{
        //     if (index > 0 ) {
        //         campaign_param += '&campaigns=' + b;
        //     }
        // });
        // const withGenpopParam = `&withGenpop=${withGenpop}`;
        return ApiData.postJson(data_url+`/profile`, payload);
    }

    static getGeoMainVariableFilterOptions(){
        return ApiData.getJson(data_url+`/profile/filters`);
    }

    static getGeoSubVariableFilterOptions(id, body){
        return ApiData.postJson(data_url+`/profile/filteroptions/${id}`,body);
    }

    static getDetailedTwoByTwoAllData(brandId, payload) {
        console.log(payload)
        /*
            for some reason this request takes a lot of time to proceed and in most cases
            fails due to connection timeout. So it is split into
         */
        const maxFilterIdsPerRequest = 5;
        const filterIds = [...payload.filtersId];
        const chunks = chunk(filterIds, maxFilterIdsPerRequest);
        const requests = chunks.map((chunk) => {
            return ApiData.postJson(admin_url+`/brand/${brandId}/twoXTwo/allSegments`, {
                ...payload,
                filtersId: chunk,
            })
        });
        return Promise
            .all(requests)
            .then((response) => response.reduce((acc, el) => ({ ...acc, ...el }), {}));
    }

    static getGeoDetailedTwoByTwoAllData(brandId, payload, campaignId) {
        console.log(payload)
        /*
            for some reason this request takes a lot of time to proceed and in most cases
            fails due to connection timeout. So it is split into
         */
        const maxFilterIdsPerRequest = 5;
        const filterIds = [...payload.filtersId];
        const chunks = chunk(filterIds, maxFilterIdsPerRequest);
        const requests = chunks.map((chunk) => {
            return ApiData.postJson(admin_url+`/brand/${brandId}/twoXTwo/allSegmentsPerCampaign/${campaignId}`, {
                ...payload,
                filtersId: chunk,
            })
        });
        return Promise
            .all(requests)
            .then((response) => response.reduce((acc, el) => ({ ...acc, ...el }), {}));
    }

    static getDetailedTwoByTwoCustomData(brandId, payload){
        return ApiData.postJson(admin_url+`/brand/${brandId}/twoXTwo/customSegments`, payload);
    }

    static getGeoDetailedTwoByTwoCustomData(brandId, payload, campaignId){
        return ApiData.postJson(admin_url+`/brand/${brandId}/twoXTwo/customSegmentsPerCampaign/${campaignId}`, payload);
    }

    static getCompetitiveTwoByTwoData(brandId, payload){
        return ApiData.postJson(admin_url+`/brand/${brandId}/twoXTwo/competitive`, payload);
    }

    static getGeoCompetitiveTwoByTwoData(brandId, payload, campaignId){
        return ApiData.postJson(admin_url+`/brand/${brandId}/twoXTwo/competitivePerCampaign/${campaignId}`, payload);
    }

    static getGeoAsyncCompetitiveTwoByTwoData(brandId, payload, campaignId){
        return ApiData.postJson(admin_url+`/brand/${brandId}/twoXTwo/asyncCompetitivePerCampaign/${campaignId}`, payload);
    }

    static getGeoTwoByTwoData(brandId, payload){
        return ApiData.postJson(admin_url+`/brand/${brandId}/twoXTwo/geoPerCampaign`, payload);
    }

    static getGeoAsyncTwoByTwoData(brandId, payload){
        return ApiData.postJson(admin_url+`/brand/${brandId}/twoXTwo/asyncGeoPerCampaign`, payload);
    }

    static getSocketAuthorizationToken(clientId){
        const params = {
            clientId: clientId
        };
        return ApiData.getJson(`${export_url}/socket/authorize`, params, true );
    }

    static handleError(response, error, callback) {
        let sessionExpiredError = false;

        if(error?.status === 401) {
            sessionExpiredError = true;
            logOut();
        }
        if (response?.data?.status === 401) {
            sessionExpiredError = true;
            logOut();
        }

        // sentryClient.captureException(err); // TODO: Implement Custom Wrapper
        if (process.env.REACT_APP_SENTRY_ENABLED && !sessionExpiredError) {
            let requestPayload = (error?.response?.config?.data) ? JSON.parse(error.response.config.data) : null;

            Sentry.captureException(error, { 
                contexts: {
                    payload: requestPayload 
                }
            });
        }

        if(response && response.text) {
            response.text().then(text => {
                if( text === ''){
                    callback(response.statusText);
                    return;
                }
                callback(text);
            })
        } 
    }

    static postJson(url, data, params='', pageInfo, extraConfig) {
        return ApiData.jsonRequest(url, 'post', params, pageInfo, data, extraConfig);
    }

    static putJson(url, data, params='', pageInfo) {
        return ApiData.jsonRequest(url, 'put', params, pageInfo, data);
    }

    static getJson(url, params='', pageInfo) {
        return ApiData.jsonRequest(url, 'get', params, pageInfo);
    }

    static delete(url, data=null) {
        return ApiData.jsonRequest(url, 'delete', null, null, data);
    }

     /**
     * page - integer for page

     size - integer for page size

     sortby - date | name which decide which properties will be used for sort

     desc - sort order. If it show up then DESC order, otherwise ASC order.

     original parameter list : (current, size, type, sortBy, sortDirectionDesc) {
     */

    static jsonRequest(url, method, params, pageInfo, data, extraConfig = {}) {

        return new Promise(async (resolve, reject) => {
            const config = {
                method: method,
                url: url,
                data: JSON.stringify(data),
                params: params,
                ...extraConfig,
            };

            apiClient.request(config)
                .then((response) => {
                    if (response.statusCode === 200 || response.status === 200) {
                        if (pageInfo) {
                            resolve(response.data)
                        } else {
                            resolve(response.data.data)
                        }
                    } else {
                       ApiData.handleError(response, null, reject);
                    }
                })
                .catch(async(err) => {
                    let sessionExpiredError = false;

                    if(err?.status === 401){
                        sessionExpiredError = true;
                        removeLocalStorage('token');
                        await logOut();
                    }

                    // sentryClient.captureException(err); // TODO: Implement Custom Wrapper
                    if (process.env.REACT_APP_SENTRY_ENABLED && !sessionExpiredError) {
                        let requestPayload = (err?.response?.config?.data) ? JSON.parse(err.response.config.data) : null;

                        Sentry.captureException(err, { 
                            contexts: {
                                payload: requestPayload 
                            }
                        });
                    }
                
                    reject(err,null,reject)
                })
        });
   }
}